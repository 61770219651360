<template>
  <div class="inside-page-content">
    <div class="paper light-grey-bg">
      <div class="block">
        <div class="top-search">
          <top-filter :config="{ bigType: true }" :hasSearch="true" @handleSearchParams="handleSearch"
            ref="search"></top-filter>
        </div>
        <div class="pro-list">
          <div class="item" v-for="(t, i) in dataList" :key="i">
            <div class="product">
              <img :src="t.pictureUrl" class="img" />
              <div class="name">{{ t.name }}</div>
              <div class="desc">{{ t.unitName }}</div>
              <div class="more" @click="navTo(t)">快速预约</div>
            </div>
          </div>
          <div class="footer">
            <div class="left">共 {{ total }} 条</div>
            <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange"
              :current-page.sync="page.pageNum" layout="prev, pager, next" :page-size="8"
              :total="total"></el-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TopFilter from '../../components/topFilter.vue'
export default {
  name: 'market',
  components: {
    TopFilter
  },
  data() {
    return {
      current: 0,
      keyword: "",
      dataList: [],
      total: null,
      page: {
        pageNum: 1,
        pageSize: 8
      },
      queryInfo: {
        keywords: null,
        type: 1
      }
    }
  },
  computed: {
  },
  created() {

  },
  watch: {
    '$route'(to, from) {
      // 获取路由参数
      this.queryInfo.type = this.$route.query.type
      this.queryInfo.keywords = this.$route.query.keywords
      console.log(this.queryInfo.type)
      console.log(this.queryInfo.keywords)
      this.$nextTick(() => {
        this.$refs.search.form.keywords = this.queryInfo.keywords
        let arr = this.$refs.search.typeList;
        for (let i = 0, l = arr.length; i < l; i++) {
          if (arr[i].code == 'bigType') {
            arr[i].current = this.queryInfo.type;
            break;
          }
        }
      })
      this.getList()
    }
  },
  mounted() {
    let query = this.$router.currentRoute.query
    if (query.keywords) {
      this.queryInfo.keywords = query.keywords
    }
    if (query.type) {
      this.queryInfo.type = query.type
    }
    this.getList();
  },
  onload() {
    console.log('onload')
  },
  methods: {
    handleSearch(params) {
      // 发起请求
      this.queryInfo.type = params.bigType
      this.queryInfo.keywords = params.keywords
      this.getList();
    },
    navTo(t) {
      this.$router.push({ path: '/sharedInstrument/instrumentDetail', query: { id: t.id, title: '大科学装置共享', type: 0 } })
    },
    getList() {
      let params = Object.assign(this.page, this.queryInfo)
      this.$api.share.search(params).then((res) => {
        let data = res.data;
        this.dataList = data.rows
        this.total = data.total
      })
    },
    handleCurrentChange(val) {
      this.page.pageNum = val;
      this.getList();
    },
    handleSizeChange(val) {
      this.page.pageSize = val;
      this.getList();
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.top-search {
  background: #fff;
  padding: 0 40px 20px;

  .form-item {
    display: flex;
    align-items: center;
    padding-bottom: 20px;

    .search {
      width: 300px;
      margin-left: 30px;
    }
  }
}

.pro-list {
  background: #fff;
  margin-top: 40px;
  padding: 30px;

  .item {
    display: inline-block;
    width: 25%;
    padding: 10px 10px 20px;
    box-sizing: border-box;

    .product {
      border: 1px solid #ececec;

      .img {
        width: 100%;
        height: auto;
        display: block;
      }

      .name {
        font-weight: 700;
        padding: 10px 20px 0;
        font-size: 16px;
        height: 50px;
      }

      .desc {
        padding: 0 20px 10px;
        font-size: 14px;
        height: 40px;
      }

      .more {
        display: inline-block;
        background-color: #0046c0;
        color: #fff;
        padding: 5px 10px;
        border-radius: 6px;
        margin: 0 20px 20px;
        cursor: pointer;
        font-size: 14px;
      }
    }
  }
}
</style>
